// Admin Layout (LUNO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: LUNO
// Copyright 2022 WrrapTheme

[data-theme="high-contrast"] {
    .text-white{
        color: var(--black-color) !important;
    }
}

[data-theme="theme-dark"] {
    [data-luno="theme-black"],
    [data-luno="theme-indigo"],
    [data-luno="theme-blue"],
    [data-luno="theme-cyan"],
    [data-luno="theme-green"],
    [data-luno="theme-orange"],
    [data-luno="theme-blush"],
    [data-luno="theme-red"] {
        .text-dark {
            color: var(--primary-color) !important;
        }
    }
}

// onepage ui kit
@import "global/variables";
@import "onepage-kit/header";
@import "onepage-kit/sliders";
@import "onepage-kit/call_action";
@import "onepage-kit/testimonials";
@import "onepage-kit/services";

.landing-page{

    &.rtl_mode{
        overflow-y: auto;
        .header {
            .hero-img{
                transform: scaleX(-1);
                left: 0;
                right: auto;
            }
            .collapse {
                form {
                    .btn {
                        margin-left: 0 !important;
                        margin-right: .5rem !important;
                    }
                }
            }
        }
        .section{
            &.testimonials{
                direction: ltr;
                text-align: left;
            }
            &.facts .col{
                border-right: 0;
                border-left: 1px solid var(--color-100);
                &:last-child{
                    border-left: 0;
                }
            }
        }
        .swiper-container{
            direction: ltr;
            text-align: left;
        }
        .accordion-button::after{
            margin-right: auto;
            margin-left: unset;
        }

        .footer-link,
        .address {
            padding-right: 2rem;
            padding-left: 0;
        }
    }

    // more layout option scss
    .main-raised{
        position: relative;
        //border-radius: 2rem;
        z-index: 99;
        top: -6rem;
        margin: 0 6rem;
        margin-bottom: -6rem;

        @media only screen and (max-width: $break-xlarge + 160px) {
            border-radius: 1rem;
            top: -3rem;
            margin: 0 3rem;
            margin-bottom: -3rem;
        }
        @media only screen and (max-width: $break-small) {
            border-radius: .5rem;
            top: -1rem;
            margin: 0 .5rem;
            margin-bottom: -1rem;
        }
    }

    // section:
    .section{
        padding: 60px 0;

        &.menubar{
            transition: all 0.5s;
            z-index: 997;
            top: -1px;

            .navbar-brand {
                font-size: 30px;
                margin: 0;
                padding: 0;
                line-height: 1;
                font-weight: 700;
                letter-spacing: 0.5px;
                color: var(--secondary-color);
            }
            .navbar-toggler {
                border-color: var(--secondary-color);
                color: var(--secondary-color);
            }
            .navbar {
                padding: 0;
                //background: var(--body-color) !important;

                .navbar-nav {
                    display: inline-flex;
                    li {
                        margin-left: 0.25rem;
                        margin-right: 0.25rem;
                        a {
                            color: var(--color-500);
                            border-bottom: 2px solid transparent;
                            font-size: 16px;
                            transition: 0.3s;
                            &:hover,
                            &:active,
                            &.active {
                                color: var(--secondary-color);
                                border-bottom-color: var(--secondary-color);
                            }
                        }
                    }
                }
            }
        }

        &.about{
            .video-box {
                background: url("../../assets/img/hospital/about.jpg") center center no-repeat;
                background-size: cover;
                min-height: 480px;
            }
            .play-btn {
                display: block;
                position: absolute;
                overflow: hidden;
                width: 94px;
                height: 94px;
                background: radial-gradient(var(--primary-color) 50%, rgba(25, 119, 204, 0.4) 52%);
                border-radius: 50%;
                left: calc(50% - 47px);
                top: calc(50% - 47px);
                
                &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translateX(-40%) translateY(-50%);
                    width: 0;
                    height: 0;
                    border-top: 10px solid transparent;
                    border-bottom: 10px solid transparent;
                    border-left: 15px solid var(--white-color);
                    z-index: 100;
                    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
                }
                &:before {
                    content: '';
                    position: absolute;
                    width: 120px;
                    height: 120px;
                    -webkit-animation-delay: 0s;
                    animation-delay: 0s;
                    -webkit-animation: pulsate-btn 2s;
                    animation: pulsate-btn 2s;
                    -webkit-animation-direction: forwards;
                    animation-direction: forwards;
                    -webkit-animation-iteration-count: infinite;
                    animation-iteration-count: infinite;
                    -webkit-animation-timing-function: steps;
                    animation-timing-function: steps;
                    opacity: 1;
                    border-radius: 50%;
                    border: 5px solid rgba(var(--primary-rgb), 0.9);
                    top: -15%;
                    left: -15%;
                    background: rgba(198, 16, 0, 0);
                }
                &:hover{
                    &:after {
                        border-left: 15px solid var(--primary-color);
                        transform: scale(20);
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        border: none;
                        -webkit-animation: none;
                        animation: none;
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                        border-left: 15px solid var(--white-color);
                        z-index: 200;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-40%) translateY(-50%);
                        width: 0;
                        height: 0;
                        border-radius: 0;
                    }
                }
            }
        }

        &.facts{
            background: var(--body-color);
            .card{
                border-color: var(--color-100);
            }
            .col{
                border-right: 1px solid var(--color-100);
                &:last-child{
                    border-right: 0;
                }
            }
        }

        &.departments {

            overflow: hidden;
            .nav-tabs {
                border: 0;
            }
            .nav-link {
                color: var(--color-600);
                border: 0;
                transition: 0.3s;
                border-radius: 0;
                border-right: 3px solid transparent;
        
                &:hover,
                &.active {
                    color: var(--secondary-color);
                    border-color: var(--primary-color);
                }
                @media (max-width: 992px) {
                    padding: 15px;
                    &.active {
                        color: var(--white-color);
                        background: var(--primary-color);
                    }
                }
            }
            .tab-pane.active {
                -webkit-animation: fadeIn 0.5s ease-out;
                animation: fadeIn 0.5s ease-out;
            }
        }

        &.footer{
            background: var(--body-color);
            .footer-top{
                color: var(--color-400);
            }
            .footer-link{
                list-style: square;
                line-height: 36px;
                font-size: 1rem;
                li a{
                    color: var(--color-500);
                    &:hover{
                        color: var(--primary-color);
                    }
                }
            }
            .address{
                line-height: 26px;
                font-size: 15px;
            }
        }
    }

    .back-to-top{
        position: fixed;
        z-index: 9999;
        opacity: .3;
        bottom: 10px;
        right: 10px;
        padding: .7rem;
        &:hover{
            opacity: 1;
            background-color: var(--primary-color);
            color: #fff;
        }
    }

    .setting-more{
        display: none;
    }
}