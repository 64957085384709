// Admin Layout (LUNO)
// @author: WrrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: LUNO
// Copyright 2022 WrrapTheme

// Theme Dark version color variable
[data-theme="dark"] {
	--dark-color: 		#262727;

	--color-fff:		#000000;
	--color-100:		#212121;
	--color-200:		#424242;
	--color-300:		#616161;
	--color-400:		#757575;
	--color-500:		#9e9e9e;
	--color-600:		#BDBDBD;
	--color-700:		#E0E0E0;
	--color-800:		#EEEEEE;
	--color-900:		#F0F0F0;
	--color-000:		#FFFFFF;

	--border-color:		#353535;
	--card-color: 		#262727;
	--body-color: 		#1e1f20;
	--white-color: 		#ffffff;
	--sidebar-color:	#262727;

	[data-luno="theme-black"] {

		--primary-color: 	#000000;
		--secondary-color: 	#0f8295;

		--primary-rgb: 12, 107, 122;

		--chart-color1:	 	#000000;
		--chart-color2: 	#A3CFCD;
		--chart-color3: 	#82A0AA;
		--chart-color4: 	#677381;
		--chart-color5: 	#A3CFCD;
	
		--primary-gradient: linear-gradient(145deg, var(--primary-color), var(--secondary-color));

		.btn{
			&.btn-primary{
				color: #000;
			}
		}
		.more-icon{
			color: rgba(#ffffff,0.5);
		}
	}

	[data-luno="theme-blush"] {
		--body-color: 		#1e1f20;
		--card-color: 		#262727;
	}
	[data-luno="theme-blue"] {
		--body-color: 		#1c1c1e;
		--card-color: 		#202022;
		--sidebar-color:	#202022;
		--primary-color: 	#2794eb;
		--secondary-color: 	#00C894;

		--primary-rgb: 108,115,211;
	}
	[data-luno="theme-blue"] {
		--body-color: 		#1d1f20;
		--card-color: 		#212324;
		--sidebar-color:	#212324;
	}



	.bg-light.text-dark{
		color: var(--white-color) !important;
	}
	.form-control,
	.form-select{
		&::placeholder{
			color: var(--color-300) !important;
		}
	}
	.form-control:disabled,
	.form-control[readonly] {
		background-color: var(--border-color);
		color: var(--color-400);
	}
	.input-group-text {
		background-color: var(--border-color);
		color: var(--color-400);
	}
	.sidebar {
		&.dark{
			.title-text{
				color: var(--white-color);
			}
		}
	}
}